import React from "react";
import { Line } from "react-chartjs-2";
import "./PopupForm.css";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

const IrrigationGraph = ({ dailyEtAdjArray,dailyEtLitersAdjArray,totalEtLitersAdj }) => {
    const labels = Array.from({ length: dailyEtAdjArray.length }, (_, i) => i + 1);

    const data = {
        labels,
        datasets: [
            {
                label: 'Daily Adjusted ETc (mm)',
                data: dailyEtAdjArray,
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
            },
            {
                label: 'Daily Adjusted Water Requirement (Liters)',
                data: dailyEtLitersAdjArray,
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 2,
                fill: false,
            }
        ]
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Days'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Values'
                }
            }
        }
    };

    return (
        <div className="chart-container">
            <h2>Irrigation Requirements</h2>
            <Line data={data} options={options} />
            <div>Total Adjusted Water Requirement (Liters): {totalEtLitersAdj}</div>
        </div>
    );
};

export default IrrigationGraph;
