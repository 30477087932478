import "./PopupForm.css";
import React, { useState, useEffect } from "react";
import { IrrigationTypes } from "./PopupForm";
import { CropTypes } from "./PopupForm";
import IrrigationGraph from "./IrrigationGraph";

const SoilTypes = { "Sandy Loam": 23, "Loamy Sand": 17, Loam: 32 };

export function PopupFormIS({ latitude, longitude, onClose }) {
  const [cropType, setCropType] = React.useState("");
  const [crop, setCrop] = React.useState("");
  const [irrigationType, setIrrigationType] = React.useState("");
  const [loading, setLoading] = useState(false);

 // const [waterRequirement, setWaterRequirement] = useState(null);
 // const [etMm, setEtMm] = useState(null);
 // const [adjustedEtMm, setAdjustedEtMm] = useState(null);
 
  //const [TotalAdjustedEtMm, setTotalAdjustedEtMm] = useState(null);
  //const [TotalWaterRequirement, setTotalWaterRequirement] = useState(null);

  
   const [dailyEtAdjArray, setdailyEtAdjArray]= useState(null);
   const [dailyEtLitersAdjArray, setdailyEtLitersAdjArray]=useState(null);
  
   const [totaldailyEtAdj, settotaldailyEtAdj]=useState(null);
   const [totalEtLitersAdj, settotalEtLitersAdj]=useState(null);
  
  
  const [area, setArea] = React.useState("1");
  const [errorMessage, setErrorMessage] = useState("");
  const [soilType, setSoilType] = React.useState(""); // new state
  const [moistureLevel, setMoistureLevel] = React.useState(""); // new state

  const handleSoilTypeChange = (e) => {
    /*const selectedSoil = e.target.value;
    setSoilType(selectedSoil);
    // set default moisture level based on selected soil type
    if (selectedSoil && moistureLevel !== SoilTypes[selectedSoil]) {
      setMoistureLevel(SoilTypes[selectedSoil].toString() || "");
    }*/

    const selectedSoil = e.target.value;
    setSoilType(selectedSoil);
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  const getMay30thOrCurrent = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const may30th = new Date(currentYear, 4, 30); // Months are 0-indexed in JS Date

    if (currentDate <= may30th) {
      return formatDate(currentDate);
    } else {
      return `${currentYear}-05-30`;
    }
  };

  const currentDate = new Date();
  const defaultSowingDate = getMay30thOrCurrent;
  const [sowingDate, setSowingDate] = React.useState(defaultSowingDate);
  const [irrigationDate, setIrrigationDate] = React.useState(defaultSowingDate);

  const oneAndHalfYearsAgo = new Date(currentDate);
  oneAndHalfYearsAgo.setMonth(currentDate.getMonth() - 18);

  const minDate = formatDate(oneAndHalfYearsAgo);
  const maxDate = formatDate(currentDate);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!cropType) {
      setErrorMessage("Please select a Crop Type.");
      return;
    }
    if (!crop) {
      setErrorMessage("Please select a Crop.");
      return;
    }
    if (!irrigationType) {
      setErrorMessage("Please select an Irrigation Type.");
      return;
    }
    if (!area) {
      setErrorMessage("Please enter the Area in acres.");
      return;
    }
    if (!sowingDate) {
      setErrorMessage("Please select a Sowing Date.");
      return;
    }
    if (!soilType) {
      setErrorMessage("Please select a Soil Type.");
      return;
    }
    if (!moistureLevel) {
      setErrorMessage("Please check the Moisture Level.");
      return;
    }

    setLoading(true);

    const formData = {
      crop,
      cropType,
      irrigationType,
      latitude,
      longitude,
      area,
      sowingDate,
      irrigationDate,
      soilType,
      moistureLevel,
    };
    console.log(JSON.stringify(formData));

    // Making a POST request to your server
    fetch(`${process.env.REACT_APP_BACKEND_URL}calcFieldEt0/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
          
		  console.log(data);
		
        //setWaterRequirement(data.totalEtLitersAdj);
        //setEtMm(data.etMm);
        //setAdjustedEtMm(data.etAdj);
		//console.log("check");
		//console.log(data.totalEtLitersAdj);
		
		//setTotalAdjustedEtMm(data.totalEtLitersAdj);
		//setTotalWaterRequirement(data.totaletLitersAdj);
		
		
		setdailyEtAdjArray(data.dailyEtAdjArray);
		setdailyEtLitersAdjArray(data.dailyEtLitersAdjArray);
		settotaldailyEtAdj(data.totaldailyEtAdj);
		settotalEtLitersAdj(data.totalEtLitersAdj);
		
		
		
		
		
        setLoading(false);
      })
      .catch((error) => {
        alert("An error occurred. Please try again later.");
        console.error("Error sending POST request:", error);
        setLoading(false);
        console.log(JSON.stringify(formData));
      });

    setErrorMessage("");
  };

  // If water requirement is set, display it
  if (totaldailyEtAdj !== null) {
    return (
      <div className="popup-container">
	  
        <div className="requirement-section">
    	  
		  <h2>Total Actual Crop Water Requirement</h2>
          <p id="total-crop-water-req">{totaldailyEtAdj} mm</p>
          <div>
            <span className="water-volume-label">Volume requirement:</span>
            <span className="water-volume-value">
              {totalEtLitersAdj} liters
            </span>
          </div>
		  
		  
		  
        </div>
        <div className="details-section">
          <p>
            <strong>Crop Type:</strong> <span>{cropType}</span>
          </p>
          <p>
            <strong>Crop:</strong> <span>{crop}</span>
          </p>
          <p>
            <strong>Soil Type:</strong> <span>{soilType}</span>
          </p>
          <p>
            <strong>Moisture Level:</strong> <span>{moistureLevel} mm</span>
          </p>
          <p>
            <strong>Irrigation Type:</strong> <span>{irrigationType}</span>
          </p>
          <p>
            <strong>Area:</strong> <span>{area} acre(s)</span>
          </p>
          <p>
            <strong>Sowing Date:</strong> <span>{sowingDate} </span>
          </p>
          <p>
            <strong>ACWR Checked Date:</strong> <span>{irrigationDate} </span>
          </p>
        </div>
		
		
		 {dailyEtAdjArray && (
                <IrrigationGraph 
                    dailyEtAdjArray={dailyEtAdjArray}
                    dailyEtLitersAdjArray={dailyEtLitersAdjArray}
                    totalEtLitersAdj={totalEtLitersAdj}
                />
            )}
		
		
		
      </div>
    );
  }

  // If loading, display spinner
  if (loading) {
    return <div className="loading-spinner"></div>;
  }

  const handleCropTypeChange = (e) => {
    setCropType(e.target.value);
    setCrop("");
  };

  const handleAreaChange = (e) => {
    const inputValue = e.target.value;
    const maxDigits = 8;

    const isNumber = /^[0-9]*$/.test(inputValue);

    // Allow input change only if the input value is a number and its length is less than or equal to the maximum digit limit
    if (isNumber && inputValue.length <= maxDigits) {
      setArea(inputValue);
    }
  };

  const handleMoistureChange = (e) => {
    const inputValue = e.target.value;
    const maxDigitsBeforeDot = 2;
    const maxDigitsAfterDot = 2;

    const isFloatOrNumber = new RegExp(
      `^\\d{1,${maxDigitsBeforeDot}}(\\.\\d{0,${maxDigitsAfterDot}})?$`
    ).test(inputValue);

    const isPotentialFloat = /^[0-9]+\.$/.test(inputValue); // Checks if the value ends with a dot but starts with a number
    const dotOccurrences = (inputValue.match(/\./g) || []).length;

    // Allow input change only if the input value is a float, a number, an empty string, potentially in the middle of typing a float, and the dot appears only once
    if (
      (isFloatOrNumber || inputValue === "" || isPotentialFloat) &&
      dotOccurrences <= 1
    ) {
      setMoistureLevel(inputValue);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-form">
        <label htmlFor="cropType">Crop Type:</label>
        <select
          id="cropType"
          value={cropType}
          onChange={(e) => setCropType(e.target.value)}
          required
        >
          <option value="">Select Crop Type</option>
          {Object.keys(CropTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label htmlFor="crop">Crop:</label>
        <select
          id="crop"
          value={crop}
          onChange={(e) => setCrop(e.target.value)}
          required
          disabled={!cropType}
        >
          <option value="">Select Crop</option>
          {cropType &&
            Array.from(CropTypes[cropType]).map((cropName) => (
              <option key={cropName} value={cropName}>
                {cropName}
              </option>
            ))}
        </select>

        <label htmlFor="soilType">Soil Type:</label>
        <select
          id="soilType"
          value={soilType}
          onChange={handleSoilTypeChange}
          required
        >
          <option value="">Select Soil Type</option>
          {Object.keys(SoilTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label htmlFor="moistureLevel">Moisture Level (mm):</label>
        <input
          type="text"
          id="moistureLevel"
          value={moistureLevel}
          required
          onChange={(e) => handleMoistureChange(e)}
        />

        <label htmlFor="irrigation-type">Irrigation Type:</label>
        <select
          id="irrigation-type"
          value={irrigationType}
          onChange={(e) => setIrrigationType(e.target.value)}
          required
        >
          <option value="">Select Irrigation Type</option>
          {Array.from(IrrigationTypes).map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>

        <label htmlFor="area">Area (in acres):</label>
        <input
          type="number"
          id="area"
          value={area}
          onChange={(e) => handleAreaChange(e)}
          placeholder="Enter area in acres"
          required
        />

        <label htmlFor="sowing-date">Sowing Date:</label>
        <input
          type="date"
          id="sowing-date"
          value={sowingDate}
          onChange={(e) => setSowingDate(e.target.value)}
          min={minDate}
          max={maxDate}
          required
        />


	 <label htmlFor="irrigation-date">ACWR Check Date:</label>
        <input
          type="date"
          id="irrigation-date"
          value={irrigationDate}
          onChange={(e) => setIrrigationDate(e.target.value)}
          min={minDate}
          
          required
        />

        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
}
